import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import EventListItem from "../ListItems/EventListItem"

const OtherEvents = ({ current }) => {
  const data = useStaticQuery(graphql`
    {
      otherEvents: allNodeEvenement(
        limit: 5
        filter: { status: { eq: true }, is_forthcoming: { eq: true } }
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          nid: drupal_internal__nid
          title
          dates: field_date {
            start: value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
            end: end_value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
          }
          place: field_emplacement
          path {
            alias
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 332
                    maxHeight: 208
                    cropFocus: CENTER
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  let otherEvents = data.otherEvents.nodes

  if (data.otherEvents.nodes) {
    otherEvents = data.otherEvents.nodes.filter(({ nid }) => {
      return nid !== current
    })
  }

  if (otherEvents.length > 4) {
    otherEvents = otherEvents.slice(0, 4)
  }

  return otherEvents.length > 0 ? (
    <section className="section-bottom page-content-suggestion">
      <div className="section-top container mx-auto px-4 border-t border-gray-200">
        <h2 className="title h2">
          <span className="block font-normal font-handwritten text-4xl text-gray-dark">
            Vivre à Coucy
          </span>
          <small className="uppercase text-primary">À lire aussi</small>
        </h2>
        <div className="columns grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
          {otherEvents.map((node, index) => (
            <div key={index} className={`column article`}>
              <EventListItem content={node} />
            </div>
          ))}
        </div>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default OtherEvents
