import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import PageHeader from "../components/PageHeader"
import HtmlParser from "../components/HtmlParser"
import OtherEvents from "../components/Blocks/OtherEvents"
import formatDate from "../Utils/formatDate"

const Event = ({ pageContext, data }) => {
  return (
    <Layout contentType={data.node.internal.type}>
      <Metas title={data.node.title} />
      <PageHeader title={data.node.title} />
      <section className="section-bottom page-content">
        <div className="container mx-auto px-4">
          <div className="block infos mb-8">
            <div className="text-secondary text-sm font-semibold mb-1">
              <span>
                {data.node.dates.end !== data.node.dates.start
                  ? `Du ${formatDate(
                      data.node.dates.start,
                      "short-yearless"
                    )} au ${formatDate(data.node.dates.end, "short")}`
                  : `Le ${formatDate(data.node.dates.start)}`}
              </span>
              {data.node.place && (
                <>
                  <span className="separator mx-1">-</span>
                  <span>{data.node.place}</span>
                </>
              )}
            </div>
          </div>

          {data.node.relationships.image && (
            <div className="block figure text-center mb-12">
              <figure className="relative bg-gray-200 leading-none aspect-w-16 aspect-h-5">
                <Img
                  fluid={
                    data.node.relationships.image.localFile.childImageSharp
                      .fluid
                  }
                  style={{ position: "absolute !important" }}
                  alt={data.node.title}
                  objectFit="contain"
                  objectPosition="50% 50%"
                />
              </figure>
            </div>
          )}

          {data.node.body && (
            <div
              className="block content mb-8 lg:px-24"
              data-typesense-field="body"
            >
              <HtmlParser html={data.node.body.value} />
            </div>
          )}
        </div>
      </section>
      <OtherEvents current={pageContext.internalId} />
    </Layout>
  )
}

export default Event

export const query = graphql`
  query ($internalId: Int!) {
    node: nodeEvenement(
      status: { eq: true }
      drupal_internal__nid: { eq: $internalId }
    ) {
      title
      body {
        value
      }
      dates: field_date {
        start: value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
        end: end_value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
      }
      place: field_emplacement
      internal {
        type
      }
      relationships {
        image: field_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 470, cropFocus: CENTER, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
